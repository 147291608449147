import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';

export const RenderDateFormatPreview = (props) => {
  return (
    <MarvelExperiment name="exp_date_format_preview">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="short_format">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
};
